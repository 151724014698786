@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  @apply box-border;
}

body {
  @apply relative bg-[#020014] text-[15px];
}

html {
  @apply scroll-smooth;
}

::-webkit-scrollbar {
  @apply w-0;
  background: transparent;
}

@media (min-width: 1024px) {
  .glass {
    @apply shadow-[0_8px_32px_0_rgba(31,38,135,0.67)] backdrop-blur-[0.1px];
    background: rgba(104, 104, 104, 0.2);
    -webkit-backdrop-filter: blur(3px);
  }
}

.image {
  @apply max-w-[calc(230vw_+_1670px)] z-[-9999];
}

.home-campaign-glowing-icon-glow {
  --mktg-accent-primary: #dd7df7;
}

.home-campaign-lines-hero {
  @apply left-[calc(50%_-_12px)] w-[300px] h-[428px] md:left-[calc(50%_-_14px)] md:w-[437px] md:h-[643px];
}

.copilot {
  /* @apply shadow-[0_8px_32px_0_rgba(31,38,135,0.67)] backdrop-blur-[1px]; */
  @apply backdrop-blur-[1px];
  background: linear-gradient(
    90deg,
    rgba(1, 1, 1, 0.6) 0%,
    rgba(1, 1, 1, 0.461139) 59.76%,
    rgba(1, 1, 1, 0) 100%
  );
  border-color: #424b5b !important;
  border-radius: 100px;
  -webkit-backdrop-filter: blur(3px);
}

.home-campaign-signup-button {
  background: linear-gradient(
      180deg,
      rgba(183, 52, 179, 0.15) 0%,
      rgba(164, 46, 156, 0) 100%
    ),
    #6e40c9 !important;
}

.rt-button-agent-submit {
  background: linear-gradient(90deg, #6e40c9 0%, #afa2f9 100%);
}

.rt-button-agent-submit:hover {
  background: none;
}

/* delete later start */
.rt-button-solid {
  @apply sm:mt-5 lg:mt-0 md:mb-0 py-3 w-full text-base lg:rounded-md max-md:rounded-md md:rounded-e-md text-white font-medium border-[#6e40c9] border-[1.5px];
  background: linear-gradient(90deg, #6e40c9 0%, #afa2f9 100%);
}

.rt-button-solid:hover {
  @apply sm:mt-5 lg:mt-0 md:mb-0 py-3 w-full text-base lg:rounded-md max-md:rounded-md md:rounded-e-md text-white font-medium border-[#6e40c9] border-[1.5px];
  background: none;
}

.rt-button-transparent {
  @apply sm:mb-5 md:mb-0 lg:mb-0 lg:rounded-md py-3 w-full text-base max-md:rounded-md md:rounded-e-md text-white font-medium border-[#6e40c9] border-[1.5px];
  background: none;
}

/* delete later end */

.home-campaign-glowing-icon-glow-1 {
  @apply bg-[#7ee787] blur-[20px];
}

.home-campaign-glowing-icon-glow-2 {
  @apply bg-[#ffa28b] blur-[20px];
}

.home-campaign-glowing-icon-glow-3 {
  @apply bg-[#939aff] blur-[20px];
}

.home-campaign-git-line {
  @apply delay-500 w-[3px];
  background: linear-gradient(#56d364, #2ea043, var(--color-canvas-default));
}

@media (max-width: 400px) {
  .line {
    @apply h-[1080px];
  }
}

.pl-ent {
  @apply text-[#7ee787];
}

.pl-e,
.pl-en {
  @apply text-[#d2a8ff];
}

.pl-s {
  @apply text-[#a5d6ff];
}

.pl-s1 {
  @apply text-white;
}

.pl-k {
  @apply text-[#ff7b72];
}

.pl-c1 {
  @apply text-[#79c0ff];
}

.color-fg-subtle {
  @apply text-[#6e7681];
}

.color-fg-accent {
  @apply text-[#2f81f7];
}

.color-fg-done {
  @apply text-[#a371f7];
}

.increase {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(88, 133, 91, 0.9) 25.52%,
    #4ead56 53.12%,
    rgba(99, 199, 107, 0.544444) 74.48%,
    rgba(0, 0, 0, 0) 100%
  );
}

.increase1 {
  background: linear-gradient(#0d1117, #ea6045, #ea6045, #0d1117);
}

.increase2 {
  background: linear-gradient(#0d1117, #797ef9, #797ef9, #0d1117);
}

.back {
  @apply blur-[180px];
  background: #7ee787;
}

.box-shadow-card-mktg {
  @apply shadow-[0_3px_2px_rgba(0,0,0,0.04),0_7px_5px_rgba(0,0,0,0.02),0_13px_10px_rgba(0,0,0,0.02),0_22px_17px_rgba(0,0,0,0.02)];
}

.code-editor-line-mktg {
  @apply inline-block border-l-2 border-l-[#0d1117] border-solid;
}

.code-editor-line-suggested-mktg {
  @apply border-[#1f6feb];
}

.box-shadow-mktg-xl {
  @apply shadow-[0_2.74416px_2.74416px_rgba(0,0,0,0.0274351),0_5.48831px_5.48831px_rgba(0,0,0,0.0400741),0_13.7208px_10.9766px_rgba(0,0,0,0.0499982),0_20.5812px_20.5812px_rgba(0,0,0,0.0596004),0_41.1623px_41.1623px_rgba(0,0,0,0.0709366),0_96.0454px_89.1851px_rgba(0,0,0,0.09)];
}

.responsive {
  @apply w-full h-auto;
}

.hero-mascot {
  @apply animate-[animate_20s_linear_infinite];
}

.rt-font-hero-title {
  @apply text-[30px] md:leading-[120%] md:text-[64px] font-semibold text-[#EFEFEF];
}

.rt-font-button {
  @apply font-medium text-white text-base max-md:text-[16px] leading-5;
}

.rt-button {
  @apply sm:mt-5 lg:mt-0 md:mb-0 w-auto lg:rounded-md max-md:rounded-md md:rounded-e-md text-white font-medium border-[#6e40c9] border-[1.5px];
}

.rt-button-solid-bg {
  @apply bg-gradient-to-r from-[#6e40c9] to-[#afa2f9];
}

.rt-button-solid-bg:hover {
  @apply bg-none;
}

.rt-button-transparent-bg {
  @apply bg-none;
}

.rt-section-title-small {
  @apply text-[20px] md:text-2xl mb-7 font-medium text-white delay-200;
}

.rt-para {
  @apply text-lg md:text-xl font-medium;
}

@keyframes animate {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;
    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;
    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --radius: 0.5rem;
  }
  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;
    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.gradient-text {
  background: linear-gradient(91deg, #9b86ff 7.88%, #d9d0ec 73.78%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.gradient-bg {
  border-radius: 20px;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.04) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    rgba(255, 255, 255, 0.04);
}

.glowing-border {
  animation: rotation 3s linear 0s infinite;
  background: linear-gradient(var(--gradient-angle), #afa2f9, rgb(0, 0, 0));
}

@keyframes rotation {
  0% {
    --gradient-angle: 0deg;
  }
  100% {
    --gradient-angle: 360deg;
  }
}
@property --gradient-angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}
